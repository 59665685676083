import React, { useEffect,useState  } from 'react'
import { Typography, Form, Input, Button, message } from 'antd'
import api from '../../api'

export default () => {
  const [form] = Form.useForm()
  const [data, setData] = useState([]);
  const getData = async () => {
    try {
      const { data } = await api.get('/content/list')
      
      setData(data || []);
      // form.setFieldValue('cskh', data.data)
    } catch (err) {}
  }

  
  const deleteUrl = async(id)=>{
    try {
      await api.post('/content/delete',{ id:id })
      getData();
      // form.setFieldValue('cskh', data.data)
    } catch (err) {}

  }
  
  const onFinish = async values => {
    try {
      await api.put('/content/add', { url: values.cskh })
      getData();
      
      message.success('Thêm thành công');
    } catch (err) {}
  }

  useEffect(() => { 
    
    getData()
  },[])

  return (
    <div>
      <Typography.Title level={5}> Thên link CSKH </Typography.Title>
      <br />
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 32 }}
        initialValues={{ cskh: '' }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <div>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH"
            name="cskh"
            rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type="primary" htmlType="submit">
              Thêm
            </Button>
          </Form.Item>
        </div>
      </Form>


       {/* Hiển thị mảng data dưới dạng <ul> và <li> */}
       <div style={{ marginTop: 30 }}>
        <Typography.Title level={5}>Danh sách CSKH</Typography.Title>
        <ul>
          {data.length > 0 ? (
            data.map((item, index) => (
              <li key={index}>{item.content} &ensp;
              <str style={{Background:'red'}}>{item.count} user</str>
              &ensp;
              <Button onClick={()=>{deleteUrl(item._id);}}>
              Xóa
              </Button>
              
              </li>  
            ))
          ) : (
            <li>Không có dữ liệu</li>
          )}
        </ul>
      </div>
    


    </div>
  )
}
